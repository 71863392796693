@import 'styles/_colors.scss';
@import 'styles/_mixins.scss';

.EditProjectPageContainer {
  h2 {
    padding: 8px;
  }

  padding-right: 25px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .btn {
    margin-top: 20px;
    width: 100%;
    background-color: transparent;
    border: 2px solid $midnight-sapphire-blue;
    border-radius: 4px;
    padding: 8px;
    color: $midnight-sapphire-blue;
    cursor: pointer;
    font-weight: 700;
    font-size: 15px;

    .loader {
      color: $midnight-sapphire-blue;
      margin-right: 20px;
    }

    &:hover {
      background-color: $midnight-sapphire-blue;
      color: $white;

      .loader {
        color: $white;
      }
    }
  }
}
