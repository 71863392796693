@import 'styles/_colors.scss';
.sidemenu {
    position: fixed;
    top: 0;
    left: 0px; /* Adjust the initial left position */
    height: 100%;
    width: 80vw;
    background-image: linear-gradient(to bottom, $midnight-sapphire-blue, $sky-blue);
    transition: left 0.3s;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    padding: 13px;
  }

  .sidemenu button {
    background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
  }

  .sidemenu__item {
    margin: 0.5rem 0;
    color: $white;
    text-decoration: none;
    cursor: pointer;
  }
  .sidemenu_icon__close{
    position: absolute;
    top: 13px;
    right: 9px;
  }
  .sidemenu.active {
    left: 0;
  }

  a {
    text-decoration: none;
    color: white;
  }
  