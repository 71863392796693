@import 'styles/_colors.scss';
@import 'styles/_mixins.scss';
@import 'styles/_vars.scss';

.blockTitleMainContainer {
    h2 {
        text-align: center;
        font-size: 30px;
        font-weight: 700;
        line-height: 37px;

        span {
            display: block;
            text-align: center;
            color: $titleAccentColor;
            font-size: 36px;
        }
    }
}

@include breakpoint-reverse(ipad) {
    .blockTitleMainContainer {
        max-width: $maxWidthDesktop;
        margin: 0 auto;
    }
}