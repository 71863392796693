@import 'styles/_colors.scss';
@import 'styles/_mixins.scss';

.editProfileContainer {
  display: flex;
  flex-direction: column;
  .headerContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .profileLogo {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: flex-end;
      border: 3px solid $midnight-sapphire-blue;
      border-radius: 50%;
      margin-right: 20px;

      svg {
        color: $midnight-sapphire-blue !important;
      }
    }
  }
  .formContainer {
    display: flex;
    flex-direction: column;
    .form {
      display: flex;
      flex-direction: column;
    }
    .inputContainer {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      .label {
        font-weight: 600;
        margin: 5px 0;
      }
      .input {
        padding: 10px;
        border: 1px solid $gray-400;
        border-radius: 5px;
        &:focus {
          outline: none;
          border: 1px solid $primary;
        }
      }
      .dateInput {
        padding: 10px;
        border: 1px solid $gray-400;
        border-radius: 5px;
        width: 150px;
        &:focus {
          outline: none;
          border: 1px solid $primary;
        }
      }
    }
    @include breakpoint-reverse(ipad) {
      .inputContainer {
        display: flex;
        flex-direction: row;

        gap: 20px;
        .dateInput {
          width: 100px;
        }
        .input {
          width: 100%;
        }
        .label {
          font-weight: 600;
          margin: 5px 0;
          min-width: 320px;
        }
      }
    }
  }
  .editButton {
    margin: 20px auto 0;
    width: 80%;
    background-color: transparent;
    border: 2px solid $midnight-sapphire-blue;
    color: $midnight-sapphire-blue;
    align-self: center;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
    font-weight: 700;
    font-size: 15px;
    &:hover {
      background-color: $midnight-sapphire-blue;
      color: $white;
    }
  }
  .confirmPassword {
    p {
      font-size: 12px;
      color: $warning;
      margin-top: 5px;
    }
  }
}
