@import 'styles/_colors.scss';
@import 'styles/_mixins.scss';

.detail {
  display: flex;
  flex-direction: column;
  .label {
    font-weight: bold;
    margin: 5px 0;
  }
  @include breakpoint-reverse(ipad) {
    flex-direction: row;
    .label {
      min-width: 400px;
    }
    .value {
      margin: 5px 0;
    }
  }
}