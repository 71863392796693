@import 'styles/_colors.scss';
@import 'styles/mixins.scss';

.agentHome {
  display: flex;
  flex-direction: column;

  .projects,
  .proposals {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    &_charts {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      @include breakpoint-reverse(ipad) {
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        width: 100%;
      }

      .lineCharts,
      .barCharts {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &_title {
          text-align: center;
          text-transform: uppercase;
        }
      }
    }

    &_btn {
      margin: 20px auto 0;
      max-width: 250px;
      width: 100%;
      background-color: transparent;
      border: 2px solid $midnight-sapphire-blue;
      color: $midnight-sapphire-blue;
      border-radius: 4px;
      padding: 8px;
      cursor: pointer;
      font-weight: 700;
      font-size: 15px;
      z-index: 1000;
      &:hover {
        background-color: $midnight-sapphire-blue;
        color: $white;
      }
    }
  }

  .topAgents {
    display: none;

    @include breakpoint-reverse(ipad) {
      display: flex;
      justify-content: space-around;
      padding: 0 20px;

      &_group {
        width: 100%;
      }

      &_agent {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        padding: 5px 0;

        &_details {
          display: flex;
          gap: 10px;
          width: 50%;
        }

        &_note {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50%;
        }
      }
    }
  }
  .topAgentsContainer {
    display: flex; /* Utilise Flexbox */
    gap: 2rem; /* Espace entre les sections */
    justify-content: center; /* Aligne les blocs horizontalement au centre */
    align-items: flex-start; /* Aligne les blocs en haut */
    margin-top: 2rem; /* Espace au-dessus des blocs */
  }
  
}
