@import 'styles/_colors.scss';
@import 'styles/_mixins.scss';

.profilApplicantContaier {
  .headerContainer {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .adressesContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .editButton {
        margin: 20px auto 0;
        width: 100%;
        max-width: 250px;
        background-color: transparent;
        border: 2px solid $midnight-sapphire-blue;
        color: $midnight-sapphire-blue;
        border-radius: 4px;
        padding: 8px;
        cursor: pointer;
        font-weight: 700;
        font-size: 15px;
        &:hover {
          background-color: $midnight-sapphire-blue;
          color: $white;
        }
  }}}