@import 'styles/_colors.scss';
@import 'styles/_mixins.scss';
@import 'styles/_vars.scss';

.footer {
    padding: 1rem;
    background-color: $bgGrey; /* Use color from your design */
    color: $defaultFontColor;
    text-align: center;
    margin-top: 80px;
  }
  .nav__item{
    padding: 0 13px 0 0;
    text-decoration: none;
    cursor: pointer;
  }

  body {
      padding-bottom: 40px;
  }

  /* Desktop styles */
  @include breakpoint-reverse(ipad) {
    .footer {
        max-width: $maxWidthDesktop;
        margin: 180px auto 0 auto;
        border-radius: $borderRadius;
    }
    .footer_links {
      display: flex;
      justify-content: center;
      padding-bottom: 10px;
    }

      body {
          padding-bottom: 80px;
      }
  }