@import 'styles/_colors.scss';
@import 'styles/mixins.scss';

.newProposalsContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  .newProposals {
    .newProposalsDetails {
      display: flex;
      flex-direction: column;
      .firstDetails {
        display: flex;
        flex-direction: column;
        .newsBtncontainer {
          color: $blue;
          display: flex;
          align-items: center;
          gap: 5px;
          align-self: center;
        }
      }
      .secondDetails {
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
      }
      .btn {
        margin: 20px auto 0;
        width: 100%;
        max-width: 250px;
        background-color: transparent;
        border: 2px solid $midnight-sapphire-blue;
        color: $midnight-sapphire-blue;
        border-radius: 4px;
        padding: 8px;
        cursor: pointer;
        font-weight: 700;
        font-size: 15px;
        &:hover {
          background-color: $midnight-sapphire-blue;
          color: $white;
        }
      }
    }
  }
  @include breakpoint-reverse(ipad) {
    .newProposals {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      .newProposalsDetails {
        width: 100%;
        display: flex;
        flex-direction: column;
        .firstDetails {
          flex-direction: row;
          justify-content: space-between;
          width: 80%;
          .newsBtncontainer {
            color: $blue;
            display: flex;
            align-items: center;
            gap: 5px;
          }
        }
        .secondDetails {
          flex-direction: row;
          gap: 20px;
        }
      }
    }
  }
}
