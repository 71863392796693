@import 'styles/_colors.scss';
@import 'styles/_mixins.scss';

.profileDetailsContainer {
  display: flex;
  flex-direction: column;
  .headerContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .profileLogo {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: flex-end;
      border: 3px solid $midnight-sapphire-blue;
      border-radius: 50%;
      margin-right: 20px;
    }
  }
  .flex {
    display: flex;
    flex-direction: column;
  }
  .label {
    font-weight: 600;
    margin-top: 2px;
  }
  .value {
    margin: 5px 0;
  }
  .list {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
  }
  @include breakpoint-reverse(ipad) {
    .flex {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 10px;
    }
    .label {
      min-width: 200px;
    }
  }
  .editButton {
    margin: 20px auto 0;
    width: 100%;
    max-width: 250px;
    background-color: transparent;
    border: 2px solid $midnight-sapphire-blue;
    color: $midnight-sapphire-blue;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
    font-weight: 700;
    font-size: 15px;
    &:hover {
      background-color: $midnight-sapphire-blue;
      color: $white;
    }
  }

  .activity {
    display: flex;
    flex-direction: column;

    .confirmed {
      span {
        display: flex;
        align-items: center;
        color: green;

        svg {
          margin-right: 10px;
        }
      }
    }
  }
}
