@import 'styles/_colors.scss';
@import 'styles/_mixins.scss';

.accept_reject {
  display: flex;
  flex-direction: column;

  .newProposition {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    width: fit-content;

    @include breakpoint-reverse(ipad) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;

      > :first-child::after {
        content: '-';
        display: block;
      }

      > :first-child {
        display: flex;
        gap: 5px;
      }
    }

    h3,
    span {
      margin: 0;
      padding: 3px 0;
    }
  }

  .confirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 5px;
    gap: 20px;
    border-top: 2px solid;
    margin-top: 5px;

    @include breakpoint-reverse(ipad) {
      border-top: none;
    }

    p,
    span {
      text-align: center;
      font-size: 14px;

      @include breakpoint-reverse(ipad) {
        font-size: 18px;
      }
    }

    p {
      margin: 10px 0;
      margin-top: 20px;
    }

    .btn_accept,
    .btn_refuse {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      font-size: 20px;
      background: transparent;
      margin-top: 10px;
      padding: 4px;
      width: 120px;

      @include breakpoint-reverse(ipad) {
        font-size: 28px;
        width: auto;
      }

      svg {
        margin-right: 5px;
        height: 25px;
        width: 25px;
      }
    }

    %btn {
      border: none;
      background: none;
      cursor: pointer;
      &:hover {
        font-weight: 700;
      }
    }
    
    .btn_accept {
      @extend %btn;
      color: $success;
    }
    
    .btn_refuse {
      @extend %btn;
      color: $vermilion;
    }

    .btn_return {
      @extend %btn;
      color: $blue;
    }
  }
}
