@import "styles/_mixins.scss";
@import 'styles/_colors.scss';
@import 'styles/_vars.scss';

.fildsContainer {
    .inputs {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        input, select {
            padding: 10px;
            margin-bottom: 10px;
            border: 1px solid gray;
            border-radius: $fieldsBorderRadius;
            height: 40px;
            font-size: 16px;
            &:focus {
                border: 1px solid $primary;
            }
         
        }
        textarea {
            padding: 10px;
            border: 1px solid gray;
            border-radius: $fieldsBorderRadius;
            min-height: 200px;
            resize: none;

        }

        select {
            height: 60px;
            background-position-y: 16px;
            background-color: #fff;
        }

        label {
            font-size: 14px;
            margin-bottom: 10px;
        }
    }
   
    } .btnContainer {
        display: flex;
        justify-content: center;
        gap: 10px;
        margin-top: 15px;

        .submitButton {
            @include default-button-filled($buttonPrimaryColor, #FFF);
        }

        .outlineButton {
            @include default-button-outline($buttonPrimaryColor);
        }

        button {

        }
}

.contactMainContainer {
    background: $bgGrey;
    border-radius: 40px;
    max-width: 860px;
    margin: 0 auto;
    padding: 30px;
    color: #475569;

    h1 {
        font-weight: 700;
        font-size: 36px;
    }

    p {
        font-size: 18px;
        line-height: 27px;
    }
}

@include breakpoint-reverse(ipad) {
    .contactMainContainer {
        max-width: 860px;
        padding: 120px;

        h1 {
            font-weight: 700;
            font-size: 36px;
        }

        p {
            font-size: 18px;
            line-height: 27px;
        }
    }

}