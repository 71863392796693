@import 'styles/_colors.scss';
@import 'styles/_mixins.scss';

.sectionContainer {
  margin:20px 0;
  .accordion {
    width: 100%;
    margin: auto;
    .btn {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 10px;
      margin-bottom: 5px;
      text-align: left;
      cursor: pointer;
      background-color: $gray-200;
      border: 1px solid $gray-100;
      border-radius: 4px;
      outline: none;
      &:hover {
        background-color: $gray-100;
      }
    }
  }
  .navbar {
    display: none;
  }
  .desktop {
    display: none;
  }
  @include breakpoint-reverse(ipad) {
    .accordion {
      display: none;
    }
    .desktop {
      display: block;
    }
    .navbar {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 10px;
      color: $midnight-sapphire-blue;
      gap: 10px;
      margin: 20px 0;
      cursor: pointer;
      button {
        background-color: $alice-blue;
        color: $midnight-sapphire-blue;
        border-radius: 4px;
        font-weight: 600;
        border:none;
        cursor: pointer;
      }
    }
  }
}