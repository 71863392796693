@import 'styles/_colors.scss';
@import 'styles/_mixins.scss';
@import 'styles/_vars.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: $white;
  /* Use color from your design */
  color: $black;

  .icons {
    path {
      color: $midnight-sapphire-blue;
    }
  }

  .logo {
    .imgLogo {
      width: 170px;
    }
  }
}

.sidemenu {
  path {
    color: $midnight-sapphire-blue;
  }
}

.header .branding {
  font-size: 1.5rem;
  /* Adjust size based on your design */
}

nav {
  display: none;
  /* Hide navigation on mobile by default */
}

.header.logo {
  width: 60%;
  display: flex;
  justify-content: center;
}

.header.icon {
  width: 20%;
  display: flex;
  justify-content: flex-end;
}

.header.menu-icon {
  width: 20%;
  display: flex;
  justify-content: flex-start;
}

.header button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

/* Desktop styles */
@include breakpoint-reverse(ipad) {
  .headerContainer {

    max-width: $maxWidthDesktop;
    margin: 0 auto;

    nav {
      box-shadow: $defaultBoxShadow;
      height: 52px;
      padding: 0 32px;
      border-radius: 50px;
    }
  }

  .logo {
    margin-right: auto;
  }

  nav {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }

  .nav__item,
  .nav__item__active {
    color: #0F172A;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
  }

  .nav__item__active {
    text-decoration: underline;
  }

  .sidemenu {
    display: none;
    /* Hide hamburger menu on desktop */
  }

  .header {
    justify-content: center;

    .icons {
      margin-left: 32px;

      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 52px;
        height: 52px;
        background: $bgIconesHeader;
        border-radius: 52px;
        color: $midnight-sapphire-blue !important;
      }

      path {
        color: $midnight-sapphire-blue;
      }
    }
  }
}
