@import 'styles/_colors.scss';
@import 'styles/_mixins.scss';

.authenticationContainer {
  max-width: 1024px;
  padding: 0.2rem;

  .login_Container {
    border-bottom: 1px solid black;
  }

  .error_message {
    color: red;
    margin-top: -10px;
    font-size: 12px;
  }

  .loader {
    color: $midnight-sapphire-blue;
    margin-right: 20px;
    &:hover {
      color: $white;
    }
  }

  .login_register_message {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    .has_account,
    .no_account {
      text-transform: uppercase;
      font-size: 18px;
      margin-bottom: 14px;
    }
  }

  .inputGroup {
    margin-bottom: 1rem;
    position: relative;
    label {
      display: block;
      margin-bottom: 0.5rem;
      font-size: 14px;
    }

    input {
      width: -webkit-fill-available;
      padding: 0.5rem;
      font-size: 1rem;
      border: 1px solid $silver-light;
      border-radius: 4px;
      
    }
    .eyeIconContainer {
      position: absolute;
      cursor: pointer;
      z-index: 100;
      right: 5px;
      top: 32px;
    }
  }

  .checkboxGroup {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    label {
      margin-left: 0.5rem;
    }
  }

  .buttonGroup {
    display: flex;
    flex-direction: column;

    button {
      margin-top: 20px;
      width: 100%;
      background-color: transparent;
      border: 2px solid $midnight-sapphire-blue;
      border-radius: 4px;
      padding: 8px;
      color: $midnight-sapphire-blue;
      cursor: pointer;
      font-weight: 700;
      font-size: 15px;
      &:hover {
        background-color: $midnight-sapphire-blue;
        color: $white;
      }
    }
  }

  &__confirmationContent {
    text-align: center;
  }

  // Desktop Styles
  @include breakpoint-reverse(ipad) {
    .inputGroup {
      display: flex;
      align-items: center;

      label {
        flex-basis: 30%;
        margin-bottom: 0;
      }

      input {
        flex-basis: 70%;
        margin-left: 1rem;
      }
      .eyeIconContainer {
        position: relative;
        right: 20px;
        cursor: pointer;
        z-index: 100;
        top: 1px;
      }
    }

    .checkboxGroup {
      display: flex;

      label {
        order: 2;
      }

      input {
        order: 1;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }

      .checkboxItem {
        width: 70%;
      }
    }

    .spacer_30 {
      width: 30%;
    }

    .buttonGroup {
      flex-direction: column;
      justify-content: space-between;
      width: 70%;
      margin-left: 0.6rem;

      button {
        flex-basis: 48%; // Allows for margin without breaking to new line
        margin-bottom: 10px;
      }
    }

    .width40 {
      width: 40%;
    }

    .centerFlex {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__confirmationContent {
      text-align: start;
    }
  }
  .btnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .btn {
      text-transform: uppercase;
      margin-top: 20px !important;
      width: fit-content;
      align-self: center;
      margin: 0 auto;
      background-color: transparent;
      border: 2px solid $midnight-sapphire-blue;
      border-radius: 4px;
      padding: 8px;
      color: $midnight-sapphire-blue;
      cursor: pointer;
      font-weight: 700;
      font-size: 15px;

      &:hover {
        background-color: $midnight-sapphire-blue;
        color: $white;
      }
    }
  }
}

.errorMessages {
  color: red;
  font-size: 12px;
  margin-top: -10px;
}
.confirmation {
  max-width: 100% !important;
}
