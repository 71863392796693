@import 'styles/_colors.scss';
@import 'styles/_mixins.scss';

.editAddressContainer {
  display: flex;
  flex-direction: column;
  .headerContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .title {
      display: flex;
      align-items: center;

      svg {
        color: $midnight-sapphire-blue !important;
      }
    }
  }
  .formContainer {
    display: flex;
    flex-direction: column;
    .form {
      display: flex;
      flex-direction: column;

      .formInputs {
        @include breakpoint-reverse(ipad) {
          display: flex;
          flex-wrap: wrap;
          gap: 15px;
        }
      }
    }
    .inputContainer {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      position: relative;
      @include breakpoint-reverse(ipad) {
        width: 49%;
      }
      .label {
        font-weight: 600;
        margin: 5px 0;
      }
      .input {
        padding: 10px;
        border: 1px solid $midnight-sapphire-blue;
        border-radius: 5px;
        &:focus {
          outline: none;
          border: 1px solid $primary;
        }
      }
      .select div:nth-child(1) {
        padding: 6px;
        border: 1px solid $midnight-sapphire-blue;
        border-radius: 5px;
      }
      .error {
        color: $warning;
        font-size: 10px;
        margin-top: 5px;
        position: absolute;
        top: 64px;
      
      }
    }
    @include breakpoint-reverse(ipad) {
      .inputContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        .error {
          top: 40px;   
        }
        .dateInput {
          width: 100px;
        }
        .input {
          width: 100%;
        }
        .label {
          font-weight: 600;
          margin: 5px 0;

          @include breakpoint-reverse(ipad) {
            min-width: 160px;
            
          }
        }

        .select {
          width: 100%;
        }
        
      }
    }
  }
  @include breakpoint-reverse(ipad) {
    .checkboxContainer {
      display: flex;
      flex-wrap: wrap;
      padding-left: 155px;

      .checkboxItem {
        width: 50%;
      }
    }
  }
  .buttonContainer {
    @include breakpoint-reverse(ipad) {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 155px;
      gap: 50px;
      width: 80%;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    .button {
      text-transform: uppercase;
      margin: 20px auto 0;
      width: 80%;
      background-color: transparent;
      border: 2px solid $midnight-sapphire-blue;
      color: $midnight-sapphire-blue;
      align-self: center;
      border-radius: 4px;
      padding: 8px;
      cursor: pointer;
      font-weight: 700;
      font-size: 15px;
      &:hover {
        background-color: $midnight-sapphire-blue;
        color: $white;
      }
    }
    .disabledButton {
      background-color: grey;
      border: 2px solid grey;
      color:black;
      cursor: not-allowed;
      text-transform: uppercase;
      margin: 20px auto 0;
      width: 80%;
      align-self: center;
      border-radius: 4px;
      padding: 8px;
    }
  }
}
