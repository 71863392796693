@import 'styles/_colors.scss';
@import 'styles/_mixins.scss';
@import 'styles/_vars.scss';

:root {
    --counter-border-radius: 220px;
    --counter-border-thickness: 2px;
    --counter-border-center-x: -70px;
    --counter-border-center-y: -105px;
}

.raisonInvestirContainer {
    margin-top: 80px;
    ol {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        gap: 40px;
        flex-direction: column;

        li {
            padding: 0 25px 25px 25px;
            border-radius: $borderRadius;
            box-shadow: $defaultBoxShadow;
            position: relative;
            overflow: hidden;
            color: #0F172A;

            h3, p {
                position: relative;
                z-index: 4;
            }

            h3 {
                font-size: 18px;
                font-weight: 700;
            }

            &:before {
                content: "";
                width: var(--counter-border-radius);
                height: var(--counter-border-radius);
                border-radius: 50%;
                display: block;
                position: absolute;
                top: var(--counter-border-center-y);
                left: var(--counter-border-center-x);
                background: linear-gradient(270deg, #fff 0%, $primary 100%);
            }

            &:after {
                content: "";
                width: calc(var(--counter-border-radius) - var(--counter-border-thickness));
                height: calc(var(--counter-border-radius) - var(--counter-border-thickness));
                border-radius: 50%;
                display: block;
                position: absolute;
                top: var(--counter-border-center-y);
                left: var(--counter-border-center-x);
                background: #FFF;
            }


            .counter {
                z-index: 1;
                position: relative;
                padding: 25px 10px 25px 0;
                display: block;
                font-size: 50px;
                font-weight: 300;
                color: $primaryColor;
            }
        }
    }
}


@include breakpoint-reverse(ipad) {
    .raisonInvestirContainer {
        margin-top: 160px;
        --counter-border-radius: 280px;
        --counter-border-thickness: 2px;
        --counter-border-center-x: -70px;
        --counter-border-center-y: -135px;

        ol {
            flex-direction: row;

            li {
                padding: 0 40px 40px 40px;

                .counter {
                    font-size: 80px;
                }
            }

            h3 {
                font-size: 24px;
            }
        }
    }
}