@import 'styles/_colors.scss';
@import 'styles/_mixins.scss';

.descriptionContainer {
  .videoContainer {
    .video {
      width: 100%;
      height: 100%;
    }
  }
}