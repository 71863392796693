@import 'styles/_colors.scss';
@import 'styles/_mixins.scss';

.propositionDetailsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .progressBarContainer {
    display: none;

    @include breakpoint(ipad) {
      display: block;
      width: 90%;
    }
  }

  .detailsSection {
    display: flex;
    width: 100%;
    border-bottom: 2px solid;

    .details {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-bottom: 15px;

      @include breakpoint(ipad) {
        align-items: center;
      }

      .detailsHeader {
        display: flex;
        margin-bottom: 10px;
        font-size: 27px;
        font-weight: 600;

        @include breakpoint(ipad) {
          font-size: 18px;
          display: flex;
          flex-direction: column;
        }

        .title {
          display: flex;
          align-items: center;
          gap: 5px;

          @include breakpoint(ipad) {
            flex-direction: column;

            .icon {
              display: none;
            }
          }
        }

        h3 {
          margin: 10px 0;
        }
      }

      .detailsBody {
        display: flex;
        width: 100%;

        .imgContainer {
          display: flex;
          flex-direction: column;

          @include breakpoint(ipad) {
            display: none;
          }

          img {
            width: 150px;
            padding-bottom: 5px;
          }

          span {
            font-weight: 700;
          }
        }

        .detailsContainer {
          display: flex;
          flex-direction: column;
          width: 100%;
          justify-content: space-around;
          padding-left: 20px;
          font-size: 18px;
          font-weight: 500;

          @include breakpoint(ipad) {
            padding-left: 0;
            font-size: 15px;
            position: relative;
          }
        }

        .status {
          display: flex;
          align-items: center;
          color: $coral-orange;
          font-weight: 600;
          font-size: 20px;

          @include breakpoint(ipad) {
            justify-content: center;
            padding-bottom: 10px;
            margin-bottom: 10%;
          }

          svg {
            height: 28px;
            width: 28px;
          }
        }

        .price {
          color: $forest-green;
          font-size: 28px;
          text-align: center;
          font-weight: 700;

          @include breakpoint(ipad) {
            font-size: 20px;
            margin-bottom: 12px;
            position: absolute;
            width: 100%;
          }
        }
      }
    }
  }

  .newProposition {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding-top: 10px;

    @include breakpoint(ipad) {
      padding-bottom: 10px;
      border-bottom: 2px solid;
      display: flex;
      flex-direction: column;
    }

    &_title {
      display: flex;
      align-items: center;
      text-align: right;
      justify-content: flex-end;
      margin: 0;
      font-weight: 500;

      @include breakpoint(ipad) {
        width: 100%;
        padding: 4px 0;
      }

      svg {
        margin-right: 5px;
      }
    }

    &_details {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;

      @include breakpoint(ipad) {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }

    &_details:nth-child(1) {
      grid-area: 1 / 1;
    }

    &_details:nth-child(2) {
      grid-area: 1 / 2;
    }

    &_details:nth-child(3) {
      grid-area: 2 / 1;
    }

    &_details:nth-child(4) {
      grid-area: 2 / 2;
    }

    h3 {
      margin: 0;
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    gap: 10%;
    margin-top: 10px;
    width: 100%;

    @include breakpoint(ipad) {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    &_accept,
    &_reject {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      font-size: 20px;
      background: transparent;
      padding: 4px;
      width: 120px;
      cursor: pointer;
      &:hover {
        font-weight: 700;
      }

      svg {
        margin-right: 5px;
      }
    }

    &_accept {
      color: $success;
      border: none;
    }

    &_reject {
      color: $vermilion;
      border: none;
    }
  }

  .description {
    padding-bottom: 10px;
    border-bottom: 2px solid;

    &_title {
      display: none;

      @include breakpoint(ipad) {
        display: block;
        font-size: 16px;
        margin: 5px 0;
      }
    }

    &_content {
      font-size: 16px;
      margin-top: 5px;

      @include breakpoint(ipad) {
        font-size: 14px;
      }
    }
  }

  .files {
    padding-bottom: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;

    @include breakpoint(ipad) {
      border-bottom: 2px solid;
    }

    .file {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 4px;
      cursor: pointer;
      &:hover {
        color: $coral-orange;
      }
      svg {
        margin-right: 5px;
        height: 30px;
        width: 30px;
      }
    }
  }
}

.print_icon {
  position: absolute;
  right: 20px;
  padding-top: 10px;
  cursor: pointer;

  @include breakpoint(mobile) {
    top: 115px;
  }
}
