@mixin breakpoint($point) {
  @if $point == xx-large {
    @media only screen and (max-width: 1599px) {
      @content;
    }
  } @else if $point == desktop {
    @media only screen and (max-width: 1279px) {
      @content;
    }
  } @else if $point == tablet {
    @media only screen and (max-width: 1024px) {
      @content;
    }
  } @else if $point == ipad {
    @media only screen and (max-width: 768px) {
      @content;
    }
  } @else if $point == mobile {
    @media only screen and (max-width: 599px) {
      @content;
    }
  } @else if $point == mobile-s {
    @media only screen and (max-width: 330px) {
      @content;
    }
  }
}

@mixin breakpoint-reverse($point) {
  @if $point == xx-large {
    @media only screen and (min-width: 1600px) {
      @content;
    }
  } @else if $point == desktop {
    @media only screen and (min-width: 1280px) {
      @content;
    }
  } @else if $point == tablet {
    @media only screen and (min-width: 1024px) {
      @content;
    }
  } @else if $point == ipad {
    @media only screen and (min-width: 768px) {
      @content;
    }
  } @else if $point == mobile {
    @media only screen and (min-width: 600px) {
      @content;
    }
  } @else if $point == mobile-s {
    @media only screen and (min-width: 300px) {
      @content;
    }
  } @else if $point == mobile-micro {
    @media only screen and (min-width: 0px) {
      @content;
    }
  }
}

@mixin make-container($padding-x) {
  width: 100%;
  padding-right: $padding-x;
  padding-left: $padding-x;
  margin-right: auto;
  margin-left: auto;
}

@mixin default-button() {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 700;
  border: 2px solid;
  max-width: fit-content;
  padding: 15px 30px;

  &:hover {
    cursor: pointer;
  }
}

@mixin default-button-filled($buttonColor, $textColor) {
  @include default-button();
  color: $textColor;
  background: $buttonColor;
  border-color: $buttonColor;
}

@mixin default-button-outline($buttonColor) {
  @include default-button();
  background: #fff;
  color: $buttonColor;
  border-color: $buttonColor;
}
