@import 'styles/_colors.scss';
@import 'styles/_mixins.scss';
@import 'styles/_vars.scss';

.demandePretMainContainer {
    margin-top: 80px;
}

.buttonsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.createAccount {
    @include default-button-filled($buttonPrimaryColor, #FFF);
}

.loginUser {
    @include default-button-outline($buttonPrimaryColor);
}

@include breakpoint-reverse(ipad) {
    .demandePretMainContainer {
        margin-top: 160px;
    }
}