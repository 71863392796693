@import 'styles/_colors.scss';
@import 'styles/_mixins.scss';

.headerContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  gap: 5px;
  .edit{
    display: flex;
    justify-self: center;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
  .progressBarContainer {
    width: 70%;
    margin: auto;
    margin-top: 20px;
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
  }
  .usage,
  .type {
    font-size: larger;
    font-weight: bold;
  }
  .status {
    color: $yellow;
    font-weight: bold;
  }
  .montant {
    color: $green;
    font-weight: bold;
  }
  .img {
    height: 100px;
    width: 100%;
  }
  .details,
  .project {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
  @include breakpoint-reverse(ipad) {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    .project {
      grid-area: 1 / 1 / 2 / 6;
      flex-direction: row;
      justify-content: center;
      gap: 20px;
      margin-bottom: 5px;
    }
    .status {
      grid-area: 2 / 3 / 3 / 5;
      margin-top: 17px;
    }
    .progressBarContainer {
      grid-area: 2 / 5 / 3 / 7;
    }
    .details {
      grid-area: 3 / 3 / 4 / 6;
    }
    .montant {
      grid-area: 4 / 3 / 5 / 7;
    }
    .imgCaontainer {
      grid-area: 2 / 1 / 5 / 3;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      .img {
        height: 200px;
        width: 200px;
      }
    }
  }
}