@import 'styles/_colors.scss';
@import 'styles/_mixins.scss';
@import 'styles/_vars.scss';

:root {
    --counter-border-radius: 420px;
    --counter-border-center-x: -120px;
    --counter-border-center-y: -265px;
}

.commentObtenirPretContainer {
    margin-top: 80px;

    ol {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        gap: 40px;
        flex-direction: column;

        li {
            padding: 0 25px 25px 25px;
            border-radius: $borderRadius;
            box-shadow: $defaultBoxShadow;
            position: relative;
            overflow: hidden;
            color: #0F172A;

            img {
                position: absolute;
                right: 25px;
                top: 0;
                z-index: 4;
                width: 128px;
            }

            h3, p {
                position: relative;
                z-index: 4;
            }

            h3 {

            }

            &:before {
                content: "";
                width: var(--counter-border-radius);
                height: var(--counter-border-radius);
                border-radius: 50%;
                display: block;
                position: absolute;
                top: var(--counter-border-center-y);
                left: var(--counter-border-center-x);
                background: #F8FAFC;
            }


            .counter {
                z-index: 1;
                position: relative;
                padding: 25px 10px 25px 0;
                display: block;
                font-weight: 300;
                font-size: 48px;
                color: $primaryColor;
            }
        }
    }
}

.intro {
    text-align: center;
    font-size: 30px;
    font-weight: 400;

    img {
        vertical-align: middle;
    }
}

@include breakpoint-reverse(ipad) {
    .commentObtenirPretContainer {
        margin-top: 160px;

        --counter-border-radius: 490px;
        --counter-border-center-x: -80px;
        --counter-border-center-y: -325px;

        ol {
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;

            li {
                max-width: 370px;
                padding: 0 40px 40px 40px;
            }
        }
    }
}