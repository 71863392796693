@import 'styles/_colors.scss';
@import 'styles/mixins.scss';

.latestProjectContainer {
  border-bottom: 1px solid $gray-100;
  padding: 5px;
  .latestProject {
    &__Image {
      img {
        width: 100%;
        height: 80px;
      }
      span {
        display: none;
      }
    }
    .latestProjectDetails {
      display: flex;
      flex-direction: column;
      .firstDetails {
        display: flex;
        flex-direction: column;
        .deskTop {
          display: none;
        }
      }
      .secondDetails {
        margin: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $green;
        font-weight: bold;
      }
      .thirdDetails {
        display: none;
      }
      .btn {
        margin: 20px auto 0;
        max-width: 250px;
        width: 100%;
        background-color: transparent;
        border: 2px solid $midnight-sapphire-blue;
        color: $midnight-sapphire-blue;
        border-radius: 4px;
        padding: 8px;
        cursor: pointer;
        font-weight: 700;
        font-size: 15px;
        &:hover {
          background-color: $midnight-sapphire-blue;
          color: $white;
        }
      }
    }
  }
  @include breakpoint-reverse(ipad) {
    .latestProject {
      &__Image {
        img {
          width: 135px;
          height: 135px;
        }
        span {
          display: block;
        }
      }
      display: flex;
      justify-content: space-between;
      gap: 20px;
      .latestProjectDetails {
        width: 100%;
        display: flex;
        flex-direction: column;

        .firstDetails {
          flex-direction: row;
          justify-content: center;
          gap: 20px;
          .deskTop {
            display: block;
          }
        }
        .secondDetails {
          flex-direction: row;
          justify-content: center;
          gap: 30px;
        }
        .thirdDetails {
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
        }
      }
    }
  }
}
