/* App.css */
@import 'styles/_colors.scss';
@import 'styles/_vars.scss';


.main {
  flex-grow: 1;
  padding: 1rem;
}

/* Main content styles */
.main {
  padding: 1rem;
  color: $defaultFontColor;
}

@media screen and (min-width: 1280px) {
  .main {
    max-width: $maxWidthDesktop;
    margin: 0 auto;
  }
}
