@import 'styles/_colors.scss';
@import 'styles/_mixins.scss';

.contactItemContainer {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  .role {
    font-weight: bold;
    margin: 10px 0;
  }
  .detailsContainer {
    display: flex;
    flex-direction: row;
    .image {
      width: 100px;
      height: 100px;
    }
    .details {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
    }
  }
}