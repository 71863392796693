@import "styles/_colors.scss";
@import "styles/_mixins.scss";


.profileDetailsContainer{
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    border-bottom: 1px solid $gray-400;
    .headerContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .headerLogo {
            margin-right: 20px;
        }
    }
    .flex {
        display: flex;
        flex-direction: column;
        .adresses {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        .editIcons {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            gap: 10px;
            .btn {
                cursor: pointer;
                &:hover {
                    color: $steel-blue;
                }           
            }
        }
        
    }
    @include breakpoint-reverse(ipad) {
        .flex {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            max-width: 98%;
            
        }
    }

    }

    .buttonGroup {
        display: flex;
        justify-content: space-between;
    
        .modal_buttons {
          margin-top: 20px;
          width: 45%;
          background-color: transparent;
          border: 2px solid $midnight-sapphire-blue;
          border-radius: 4px;
          padding: 8px;
          color: $midnight-sapphire-blue;
          cursor: pointer;
          font-weight: 700;
          font-size: 15px;
          &:hover {
            background-color: $midnight-sapphire-blue;
            color: $white;
          }
        }
      }