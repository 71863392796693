
$white: #fff !default; // used
$dark-gray: #333 !default; // used
$gray-25: #ebe9f1 !default;
$gray-50: #f6f6f6 !default;
$gray-75: #f9f9f9 !default;
$gray-100: #babfc7 !default;
$gray-200: #ededed !default;
$gray-250: #f2f4f8 !default;
$gray-300: #dae1e7 !default;
$gray-400: #636363 !default;
$gray-500: #adb5bd !default;
$gray-600: #9da5b4 !default;
$gray-650: #5f6d7e !default;
$gray-700: #4e5154 !default;
$gray-800: #1e1e1e !default;
$gray-900: #2a2e30 !default;
$black: #22292f !default;
$blue: #2753b5 !default;
$cobalt-blue: #003cc2 !default;
$midnight-blue: #16264a !default;
$midnight-black: #0f1724d9 !default;
$dark-midnight-blue: #2b2b2b !default;
$midnight-sapphire-blue: #003c8a !default;
$sky-blue: #00aaff !default;
$dim-gray: #eaebf0 !default;
$dim-gray-2: #6f6f6f !default;
$gray34: #575757 !default;
$suva-gray: #8a8a8a !default;
$light-gray: #c4c4c4 !default;
$soft-pink: #f7b4b4 !default;
$peachy-pink: #f49595 !default;
$yellow: #f1b44c !default;
$blue-gray: #edf3ff !default;
$blue-gray-200: #8ea6dc !default;
$baby-powder: #f2f6ff !default;
$dim-blue-gray: #e6edff !default;
$snow-white: #fbfcff !default;
$pale-blue: #f4f7ff !default;
$pale-aquamarine: #dffff3 !default;
$powder-blue: #b7ccfb !default;
$crayola: #abafc2 !default;
$azureish-white: #e3e6ef !default;
$ultramarine-blue: #3371fa !default;
$taupe-gray: #878787 !default;
$cadet-blue: #afb4cd !default;
$greyish-white: #ffffff33 !default;
$alice-blue: #e9f2ff !default;
$lavender: #e5edff !default;
$shadow: #00000029 !default;
$dark-shadow: #00000075 !default;
$darkest-shadow: #080632b1 !default;
$blue-yonder: #55759c !default;
$light-perwinkle: #c2cddb !default;
$bluish-gray: #9299b803 !default;
$anti-flash-white: #f1f2f6 !default;
$soft-green: #34c38f1a !default;
$green: #34c38f !default;
$pale-orange: #f1b44c1a !default;
$rosy-haze: #f46a6a1a !default;
$primary-010: #175fff10 !default;
$dark-black: #000 !default; // used
$silver-Foil: #b0b0b0 !default;
$silver: #d9d9d9 !default;
$silver-light: #ccc !default; // used
$cultured: #f5f5f5 !default;
$blueberry: #4189f8 !default;
$pale-jade: #dfffd8 !default;
$moss-green: #5c9f5e !default;
$baby-blue: #b4e4ff !default;
$steel-blue: #3789bc !default;
$sky-blue: #c1daff !default;
$redwood: #a93b2f !default;
$pearl: #fff1ea !default;
$wine: #9f362e !default;
$american-Silver: #ccd0d5 !default;
$vermilion: #df3f24 !default;
$faint-grayish-overlay: #00000003 !default;
$blueviolet: blueviolet !default;
$blue: blue !default;
$orangered: orangered !default;
$transparent-black: #00000080 !default;
$semi-transparent-black: rgba(0, 0, 0, 0.5019607843) !default;
$brilliant-azure: #4096ff !default;
$goldenrod: #ffd809 !default;
$terra-cotta: #e87764 !default;
$lavender: #e8edfb !default;
$coral-orange: #fea16f !default;
$forest-green: #2aa368 !default;

$primary: #175fff !default;
$secondary: #74788d !default;
$tertiary: #c2d5ff !default;
$info: #487ef6 !default;
$warning: #f46a6a !default;
$success: #2ead7f !default;

/* Variables from Figma */
$primaryColor: #0D76FF !default;
$grey6 : #F6F7F9 !default;
$bgGrey: #F1F5F9;
$bgIconesHeader: #F1F5F9 !default;
$buttonPrimaryColor: $primaryColor;
$defaultFontColor: #475569 !default;
$titleAccentColor: $primaryColor;