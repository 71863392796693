@import 'styles/_colors.scss';
@import 'styles/_mixins.scss';

.propositionItemContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  .header {
    display: flex;
    flex-direction: column;
    .title {
      font-size: 20px;
      font-weight: bold;
    }
  }
  .btn {
    margin: 20px auto 0;
    width: 80%;
    background-color: transparent;
    border: 2px solid $midnight-sapphire-blue;
    color: $midnight-sapphire-blue;
    align-self: center;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
    font-weight: 700;
    font-size: 15px;
    &:hover {
      background-color: $midnight-sapphire-blue;
      color: $white;
    }
  }
  .desktop {
    display: none;
  }
  .accepted,
  .denied {
    font-weight: bold;
  }
  .accepted {
    color: $green;
  }
  .denied {
    color: $warning;
  }
  @include breakpoint-reverse(ipad) {
    .desktop {
      display: block;
    }
    .header {
      display: flex;
      flex-direction: row;
      width: 95%;
      justify-content: space-between;
      .info {
        display: flex;
        flex-direction: row;
        gap: 10px;
      }
      .accepted,
      .denied {
        display: flex;
        align-items: center;
        gap: 2px;
        padding-top: 5px;
      }
    }
  }
}
