@import 'styles/_colors.scss';
@import 'styles/_mixins.scss';

.propositions_list_container {
  nav {
    background-color: unset;
    display: flex;
    box-shadow: unset;
    padding: 0;
  }

  .filters_container {
  
    .filters {
      display: flex;
      margin-top: 10px;
      flex-wrap: wrap;
      gap: 5px;
      padding: 10px ;
      background-color: ghostwhite;
      div {
        margin: 0;
        margin-right: 8px;
      }

      .date_picker {
        margin-right: 0;
      }
    }
  }

  .pagination_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 5px;
  }

  .one_proposition_container {
    margin: 20px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $gray-100;

    .description_status_container {
      display: flex;
      justify-content: center;

      .firstDetail {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .firstBox {
          display: flex;
          align-items: center;
          flex-direction: column;
        }
      }

      @include breakpoint-reverse(ipad) {
        flex-direction: row;
        gap: 20px;
        justify-content: space-between;
        .firstDetail {
          flex-direction: row;
          gap: 20px;
          justify-content: space-between;
        }
        .description_container {
          width: 100%;
        }
      }

      @include breakpoint(mobile) {
        flex-direction: column-reverse;
      }

      .description_container {
        display: flex;
        flex-direction: column;
        .proposition_title {
          font-size: 20px;
          font-weight: 700;
          margin: 0;
        }

        span {
          margin: 8px 0;
        }
      }

      .accepted {
        color: $green;
        font-size: 20px;
        display: flex;
        margin-bottom: 10px;

        svg {
          margin-right: 5px;
        }
      }

      .declined {
        color: $warning;
        font-size: 20px;
        display: flex;
        margin-bottom: 10px;

        svg {
          margin-right: 5px;
        }
      }
    }

    .proposition_comment {
      font-size: 14px;
      margin-top: 8px;

      @include breakpoint(mobile) {
        display: none;
      }
    }

    .go_to_proposition {
      margin-top: 20px;
      max-width: 250px;
      align-self: center;
    }
  }
  .go_to_proposition {
    margin-top: 20px;
    width: 100%;
    background-color: transparent;
    border: 2px solid $midnight-sapphire-blue;
    border-radius: 4px;
    padding: 8px;
    color: $midnight-sapphire-blue;
    cursor: pointer;
    font-weight: 700;
    font-size: 15px;
    &:hover {
      background-color: $midnight-sapphire-blue;
      color: $white;
    }
  }
  .sort {
    display: flex;
    align-items: center;
    gap: 2px;
    button {
        border: none;
        background-color: transparent;
        color: $gray-400;
        font-size: 32px;
        margin-left:-10px ;
    }
}
}
