@import 'styles/_colors.scss';
@import 'styles/_mixins.scss';

.filterContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 10px;
    flex-wrap: wrap;
    gap: 10px;
    background-color: ghostwhite;
    @include breakpoint(ipad) {
        padding: 0;
    }
    .filter {
        display: flex;
        align-items: center;
        gap: 2px;
        input , select {
            width: 80px;
            border-radius: 5px;
            padding: 0 2px;
            border : 1px solid $gray-400;
        }
    }
    .picker {
       margin-left: 10px;
    }
}
.sortContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    gap: 50px;
    @include breakpoint(ipad) {
        flex-direction: column;
        gap: 0;
    }
    select {
        width: 80px;
        border-radius: 5px;
        padding: 0 2px;
    }
    .sort {
        display: flex;
        align-items: center;
        gap: 2px;
        button {
            border: none;
            background-color: transparent;
            color: $gray-400;
            font-size: 32px;
            margin-left:-10px ;
        }
    }
}
