@import 'styles/_colors.scss';
@import 'styles/mixins.scss';

.newProjectContainer {
  border-top: 1px solid $dark-gray;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  .btn {
    margin: 20px auto 0;
    max-width: fit-content;
    width: 100%;
    background-color: transparent;
    border: 2px solid $midnight-sapphire-blue;
    color: $midnight-sapphire-blue;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
    font-weight: 700;
    font-size: 15px;
    &:hover {
      background-color: $midnight-sapphire-blue;
      color: $white;
    }
  }
}
