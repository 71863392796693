@import 'styles/_colors.scss';
@import 'styles/_mixins.scss';

.blockTitle {
    background-color: $midnight-sapphire-blue;
    color: $white;
    padding:  5px 0 ;
    width: 100%;
    margin: 20px 0;
    border-radius: 4px;
}
.textAreaContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    margin-top: 20px;
    .inputTextarea {
        padding: 10px;
        border: 1px solid $gray-400;
        border-radius: 5px;
        min-height: 150px;

        &:focus {
            outline: none;
            border: 1px solid $primary;
        }
    }
}
.inputContainer {
    margin: 20px 0 0 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
    .input {
        padding: 10px;
        border: 1px solid $gray-400;
        border-radius: 5px;
        &:focus {
            outline: none;
            border: 1px solid $primary;
        }
    
    }
    @include breakpoint-reverse(ipad) {
        width: 48%;
        gap: 5px;
    }
}

.inputsBlock {
    display: flex ;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}