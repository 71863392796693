@import 'styles/_colors.scss';
@import 'styles/_mixins.scss';

.addressBookItemContainer {
  display: flex;
  flex-direction: column;
  .headerContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .iconContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
    }
  }
  .editButton {
    max-width: 200px;
    background-color: transparent;
    border: 2px solid $midnight-sapphire-blue;
    color: $midnight-sapphire-blue;
    border-radius: 4px;
    padding: 8px;
    font-weight: 700;
    font-size: 15px;
    cursor: pointer;
    @include breakpoint(ipad) {
      margin: 5px auto 10px;
    }

    &:hover {
      background-color: $midnight-sapphire-blue;
      color: $white;
    }
  }
  .filterContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 20px;
    flex-wrap: wrap;
    gap: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid $gray-400;
    background-color: ghostwhite;
    @include breakpoint(ipad) {
      padding: 0;
    }

    .filter {
      display: flex;
      align-items: center;
      gap: 2px;
      select {
        width: 80px;
        border-radius: 5px;
        padding: 0 2px;
        border: 1px solid $gray-400;
      }
    }
  }
  .adressDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid $gray-400;
    @include breakpoint(ipad) {
      flex-direction: column;
    }
    .sort {
      display: flex;
      align-items: center;
      gap: 2px;
      select {
        width: 80px;
        border-radius: 5px;
        padding: 0 2px;
      }
    }
  }
  .adress {
    border-bottom: 1px solid $gray-400;
    margin-top: 10px;
    padding-bottom: 10px;
  }
  @include breakpoint-reverse(ipad) {
    .headerContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .editButton {
        max-width: 200px;
        margin-top: 15px;
      }
    }
  }
  .adressContainer {
    margin-top : 20px;
  }
}
