@import 'styles/_mixins.scss';
@import 'styles/_colors.scss';

.filterContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 10px;
  flex-wrap: wrap;
  gap: 10px;
  background-color: ghostwhite;
  @include breakpoint(ipad) {
    padding: 0;
  }
  .filter {
    display: flex;
    align-items: center;
    gap: 2px;
    input,
    select {
      width: 80px;
      border-radius: 5px;
      padding: 0 2px;
      border: 1px solid $gray-400;
    }
  }
  .picker {
    margin-left: 10px;
  }
  .dateContainer {
    display: flex;
    justify-content: space-between;
    gap: 3px;
    border: 1px solid $silver-light;
    border-radius: 5px;
    padding: 2px;
    align-items: center;
    padding-left: 10px;
    max-height: 100px;
    .label {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.60);
    }
    .inputs {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 5px;
      div {
        display: flex;
        align-items: center;
        gap: 5px;
      }
      span {
        display: flex;
        align-items: center;
        gap: 5px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.60);
        font-size: small;
    }
      input {
        height: 19px;
        border-radius: 2px;
        padding: 2px 0px;
        background-color: transparent;
        border: none;
        color: $gray-400;
      }
    }
  }
}
