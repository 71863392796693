.bordered-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 18px;
    text-align: left;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  }
  
  .bordered-table th,
  .bordered-table td {
    border: 1px solid #cdf3f3;
    padding: 12px 15px;
  }
  
  .bordered-table th {
    background-color: #c2edf0;
    color: #333;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
  
  .bordered-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .bordered-table tr:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }
  
  .bordered-table th:first-child,
  .bordered-table td:first-child {
    border-left: 2px solid #ddd;
  }
  
  .bordered-table th:last-child,
  .bordered-table td:last-child {
    border-right: 2px solid #ddd;
  }
  